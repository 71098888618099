@import './Variables.scss';

body {
  height: 100vh;
  background-color: #f4f4f4 !important;
  font-size: 0.875rem;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1b6b6;
    outline: 1px solid slategrey;
  }
}

.no-border {
  border-width: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-margin-x {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-margin-y {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.no-margin-label {
  label {
    margin: 0px !important;
  }
}

.no-padding {
  padding: 0px !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-space-bw {
  justify-content: space-between !important;
}

.justify-space-ar {
  justify-content: space-around !important;
}

.pos-relative {
  position: relative;
}

.has-scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1b6b6;
    outline: 1px solid slategrey;
  }
}

.MuiIconButton-root {
  padding: 0px !important;
}

/*--Mixins Starts Here--*/
@mixin border-radius($val) {
  border-radius: $val;
  -webkit-border-radius: $val;
  -ms-border-radius: $val;
}

@mixin boxShadow($val) {
  box-shadow: $val;
  -webkit-box-shadow: $val;
  -ms-box-shadow: $val;
}

@mixin animate-css($props) {
  transition: $props !important;
  -webkit-transition: $props !important;
  -moz-transition: $props !important;
}

/*-- Mixins ends here --*/

.custom-dialog {
  &.with-padding {
    .MuiDialogContent-root {
      padding: 20px;
    }
  }

  .MuiDialogTitle-root {
    background-color: $PrimaryColor;
    padding: 12px;

    .MuiTypography-root {
      color: #fff !important;
      font-size: 16px;
      font-weight: 500;
    }

    .MuiIconButton-root {
      color: #fff;
    }

    .MuiButtonBase-root {
      position: absolute;
      top: 12px;
      right: 8px;
    }
  }

  .MuiDialogContent-root {
    padding: 0px;
  }
}

.custom-dialog.dialog-450 {
  .MuiDialog-paperWidthSm {
    max-width: 475px;
  }
}

.custom-dialog.margin-left-250 {
  .MuiDialog-scrollPaper {
    margin-left: 250px;
    margin-right: 20px;
  }

  .MuiDialogActions-root.MuiDialogActions-spacing {
    padding-right: 15px;
  }
}

.custom-dialog.dialog-500 {
  .MuiDialog-paperWidthSm {
    max-width: 525px;
  }
}

.custom-dialog.dialog-501 {
  .MuiDialog-paperWidthSm {
    max-width: 525px;
    min-width: 525px;
  }
}

.custom-dialog.dialog-625 {
  .MuiDialog-paperWidthSm {
    max-width: 625px;
  }
}

.custom-dialog.dialog-700 {
  .MuiDialog-paperWidthSm {
    max-width: 700px;
  }
}

.MuiDialogContent-root.MuiDialogContent-dividers {
  overflow-y: auto;
}

.custom-dialog.dialog-800 {
  .MuiDialog-paperWidthSm {
    max-width: 800px;
    max-height: 525px;
    overflow-y: auto;
    display: block !important;
  }

  .MuiDialogContent-root::-webkit-scrollbar {
    display: none !important;
  }
}

.custom-dialog.dialog-800-600 {
  .MuiDialog-paperWidthSm {
    max-width: 800px;
    max-height: 600px;
    overflow-y: auto;
    display: block !important;
  }

  .MuiDialogContent-root::-webkit-scrollbar {
    display: none !important;
  }
}

.custom-dialog.dialog-900 {
  .MuiDialog-paperWidthSm {
    max-width: 900px;
    top: 30px;
    left: 30px;
    padding-bottom: 20px;
  }
}

.App-logo {
  height: 40vmin;
}

.collapsable-panel {
  padding: 0px 15px;
}

.original-tabred {
  border-bottom: 2px solid $ErrorColor !important;
}


.MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error {
  color: $ErrorColor !important;
}
.nav-user-dropdown {
  button.dropdown-toggle {
    padding: 0px !important;
    padding-right: 8px !important;
    border: solid 1px rgba(0, 0, 0, 0) !important;
    &:focus {
      border: solid 1px transparent !important;
    }
  }
  button.dropdown-toggle::after{
    display: none;
  }
  button.btn-secondary:hover{
    background-color: white !important;
  }
  button.btn-secondary:focus{
    background-color: white !important;
  }
  .dropdown-menu.show {
    min-Width: 320px !important;
    right: 0px !important;
    margin: 15px !important;
    left: auto !important;
    transform: translate3d(0px, 45px, 0px) !important;
    -webkit-transform: translate3d(0px, 45px, 0px) !important;
    @include border-radius(4px);
  }
}

.nav-menu span:hover a {
  text-decoration: none !important;
  font-size: 15px;
}

.welcometxt {
  color: black;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  width: 100%
}

.text-black {
  color: rgba(0, 0, 0, 0.74) !important;
}

.navbar-blue-bg .MuiSvgIcon-root {
  color: #fff !important;
}

.pd-15 {
  padding: 0px 24px;
}

.MuiInputLabel-root {
  color: #000000 !important;
}

.thirdMenu {
  padding-left: 20px;
}

.dib {
  display: inline-block !important;
}

.db {
  display: block !important;
}

.dif {
  display: flex !important;
}

/*** Styling Form Elements start ***/
.mui-custom-form {
  &.with-link {
    .MuiInput-input {
      color: $PrimaryColor !important;
      font-weight: 400;
    }
  }

  .MuiFormLabel-root {
    font-size: 20px !important;
    white-space: nowrap;
    color: #000000 !important;
  }

  .MuiInput-input {
    color: #000;
    font-size: 19px;
    width: 100%;
  }

  .input-text {
    color: #000;
    font-size: 16px;
  }

  .sub-radio {
    margin-top: 7px;
    font-size: 13px;

    .sub-label {
      font-size: 11px !important;
    }

    .MuiFormControlLabel-root {
      margin: 0px;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    .MuiCheckbox-root {
      padding: 0px 5px;
    }

    .MuiFormControlLabel-label {
      font-size: 13px;
      margin-left: 3px !important;
    }
  }

  .Mui-error,
  .Mui-required {
    color: $ErrorColor !important;
  }

  .MuiSelect-select {
    margin-top: 3px;
  }

  .MuiTextField-root,
  .MuiInput-input {
    width: 100% !important;
  }
}

.MuiFormLabel-root label {
  background-color: #fff !important;
}

/*** Styling Form Elements end ***/
* {
  font-family: 'Open Sans', sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: $WhiteColor;
}

.no-border {
  border-width: 0px !important;
}

.input-92 {
  width: 92px;
}

.input-94 {
  width: 35px;
  padding-left: 7px;
}

.main-body {
  position: relative;
  height: 100%;
}

#root {
  height: 100% !important;
}

.App-logo {
  height: 40vmin;
}


.top-logo-bar {
  height: 65px;
  width: 100%;
  max-width: calc(100% - 200px);
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    height: 60px;
    padding-right: 12px;

    img {
      height: 55px;
      width: auto;
    }
  }

  .logo-text {
    vertical-align: middle;
    font-weight: bold;
    line-height: 65px;
    font-size: 16px;
    padding: 5px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
  }
}

.MuiFormHelperText-root.Mui-error.Mui-required {
  color: $ErrorColor !important;
  font-size: 0.75rem;
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: $ErrorColor !important;
}

.MuiFormLabel-asterisk.MuiInputLabel-asterisk.Mui-error {
  color: $ErrorColor !important;
}

.collapsable-panel .panel-header {
  padding: 9px 15px;
  background-color: $PrimaryColor;
  min-height: auto !important;
  border-radius: 4px 4px 0px 0px;
}

.collapsable-panel .MuiExpansionPanel-root {
  border: 1px solid #aaafb9 !important;
  border-radius: 4px 4px 0px 0px;
  border-top-width: 0px !important;
  margin-bottom: 10px !important;
}
.collapsable-panel .MuiExpansionPanelDetails-root {
  display: block !important;
  padding: 16px !important;
}

.collapsable-panel .panel-header .MuiExpansionPanelSummary-content {
  margin: 0px;
}

.collapsable-panel .panel-header .MuiIconButton-root {
  padding: 0px !important;
  color: #fff;
}

.collapsable-panel .panel-header .MuiExpansionPanelSummary-content .MuiTypography-root {
  color: #ffffff !important;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.label-gender {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
  margin-right: 5px;
}

.btn.btn-sm.btn-primary {
  padding: 3px 7px !important;
}

.tabs-container {
  padding: 8px 8px !important;
  height: 100%;
  background-color: #ffffff;
  // padding: 20px 23px !important;
  box-shadow: 0px 20px 40px #1827381C;

  .tab-header {
    height: 50px;
    margin-bottom: 8px;

    .tab-heading {
      color: #000;
      padding: 12px 0px;
      height: 100%;
      font-weight: 600;
      font-size: 1.135em !important;
      /* 1.750 em */
      letter-spacing: 0.1px;
      margin-bottom: 0px;
    }

    &.mini-tab-header {
      .tab-heading {
        padding: 12px 15px 7px 15px;
        font-size: 14px;
      }

      .btn {
        padding: 3px 10px !important;
        font-size: 13px !important;
      }
    }
  }

  .tab-body {
    border-radius: 5px;
    padding-bottom: 10px;
  }

  .tab-panelbody {
    box-shadow: 0px 2px 10px #16263817;
    margin: 20px 20px 20px 20px;
    padding-bottom: 20px;
  }

  .form-border {
    border: solid 1px #ececec !important
  }
}

.App-link {
  color: #09d3ac;
}

.rdt_TableHeadRow {
  background-color: $PrimaryColor;
  color: white;
}

.rdt_TableHeadRow .sc-jTzLTM {
  color: white;
  font-weight: bold;
  font-size: 14px
}

.hLrWNh,
.inhAeF {
  color: #ffffff !important;
}

.gXlqCi {
  display: none !important;
}

.btn {
  padding: 9px 20px;
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  /* 1em */
  border-radius: 5px;
  padding: 8px 15px !important;
  text-transform: uppercase;
  border-width: 0px !important;
  box-shadow: 12px 3px 12px rgba(0, 0, 0, 0.175) !important;
  -moz-box-shadow: 12px 3px 12px #2744634D !important;
  -webkit-box-shadow: 12px 3px 12px #2744634D !important;
  -ms-box-shadow: 12px 3px 12px #2744634D !important;

  .fa {
    margin-right: 5px;
  }
}

.btn1 {
  padding: 9px 20px;
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.699rem !important;
  border-radius: 5px;
  padding: 7px 13px !important;
  text-transform: uppercase;
  border-width: 0px !important;
  box-shadow: 12px 3px 12px #2744634D !important;
  -moz-box-shadow: 12px 3px 12px #2744634D !important;
  -webkit-box-shadow: 12px 3px 12px #2744634D !important;
  -ms-box-shadow: 12px 3px 12px #2744634D !important;

  .fa {
    margin-right: 5px;
  }
}

.btn-text {
  padding: 9px 20px;
  color: #274463 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 5px;
  padding: 8px 15px !important;
  text-transform: uppercase;
  border-width: 0px !important;

  //box-shadow: 12px 3px 12px #2744634D !important;
  .fa {
    margin-right: 5px;
  }
}

.btn.btn-text-main-delete {
  // padding: 9px 20px;
  color: #274463 !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  border-radius: 0.25rem;
  padding: 8px 15px !important;
  text-transform: uppercase;
  border: none !important;
  box-shadow: inset 0 0 0px 0.08em #274463 !important;

  .fa {
    margin-right: 5px;
    font-size: 1.1em;
  }
}

.btn-text-main-add {
  padding: 8px 15px !important;
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  border-radius: 0.25rem;
  text-transform: uppercase;
  border-width: 0px !important;

  //box-shadow: 12px 3px 12px #2744634D !important;
  .fa {
    margin-right: 5px;
    font-size: 0.9em;
  }
}

.btn-text-main-add:disabled {
  opacity: .65;
}

.btn-text1-delete {
  font-weight: 600 !important;
  font-size: 0.699rem;
  border-radius: 5px;
  padding: 5px 13px !important;
  text-transform: uppercase;
  border-width: 0px !important;

  //box-shadow: 12px 3px 12px #2744634D !important;
  .fa {
    margin-right: 5px;
  }
}


.btn-text1 {
  padding: 9px 20px;
  color: #274463 !important;
  font-weight: 600 !important;
  font-size: 0.699rem;
  border-radius: 5px;
  padding: 7px 13px !important;
  text-transform: uppercase;
  border-width: 0px !important;

  //box-shadow: 12px 3px 12px #2744634D !important;
  .fa {
    margin-right: 5px;
  }
}

.btn-textConfig {
  padding: 9px 20px;
  color: #274463 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 5px;
  padding: 5.7px 15px !important;
  text-transform: uppercase;
  border-width: 0px !important;

  //box-shadow: 12px 3px 12px #2744634D !important;
  .fa {
    margin-right: 5px;
  }
}

.btn-primary,
.badge-primary {
  background-color: #274463 !important;
}

.btn-secondary,
.badge-secondary {
  background-color: #0147ba !important;
}

.btn-success {
  background-color: #02837B !important;
}

.btn-Attachment {
  background-color: #A9A9A9 !important;
}

.btn-DeletConfig {
  background-color: #02837B !important;
  border: 1px solid #274463 !important;
}

.btn-transparent {
  background-color: transparent !important;
  border: 1px solid #274463 !important;
}

.btn-padding-transparent {
  padding: 7px 15px !important;
}

.nav-menu span:hover a {
  text-decoration: none !important;
  font-size: 15px;
}

.action-buttons .btn {
  float: none !important;
  margin-right: 5px;
}

.action-buttons {
  text-align: center;
  margin-top: 20px;
}

.nav-menu {
  height: 23px;
  overflow: hidden;

  a {
    cursor: pointer;
  }
}

.nav-menu span {
  border-right: 1px solid #007bff;
}

.nav-menu span:last-child {
  border-right-width: 0px;
}

.nav-menu span a {
  color: #0047BA !important;
  font-size: 13px;
}

.mt-6 {
  margin-top: 6px;
}

.MuiDrawer-paper {
  top: 0 !important;
}

.v-align-end {
  align-items: flex-end !important;
}

.tab-container {
  box-shadow: 0px 2px 10px #16263817;
  margin: 20px;
  padding-bottom: 50px;
  border-radius: 5px;
}

.tab-holder {
  padding: 0px 15px;

  .MuiBox-root {
    padding: 0px;
  }
}

.tab-holder-p-8 {
  padding: 0px 8px;

  .MuiBox-root {
    padding: 0px;
  }
}

.tab-holder-p-7 {
  padding: 0px 7px;

  .MuiBox-root {
    padding: 0px;
  }
}

.tab-holder-p-7>.MuiTypography-body1 {
  border-top: 1px solid #aaafb9;
  border-radius: 0px 0px 4px 4px;
}

.tab-holder.p-20 {
  padding: 0px 20px !important;
}

.tab-holder .makeStyles-container-154 {
  padding: 0px 24px;
}

.MuiAppBar-colorPrimary {
  background-color: #fff !important;
  box-shadow: none !important;
  border-radius: 4px 4px 0px 0px;
  // border-bottom: 1px solid #dadddc;
}

.MuiAppBar-colorPrimary .MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected {
  // background-color: #ffffff !important;
  color: #274463;
  border-bottom-width: 2px !important;
  border-bottom-color: #274463 !important;
  border-bottom: 2px solid #274463;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  outline: none;
}

.MuiTab-root {
  outline: none !important;
}

.MuiAppBar-colorPrimary.MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected:first-child:hover {
  margin-left: 7px !important;
  transform: scale(1.12);
  border-bottom: 2pxsolid#274463;
}

.MuiAppBar-colorPrimary.MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected:focus {
  outline: none !important;
}

.MuiAppBar-colorPrimary.MuiButtonBase-root.MuiTab-textColorInherit.MuiTouchRipple-root:focus {
  outline: none !important;
}

.MuiAppBar-colorPrimary .MuiButtonBase-root.MuiTab-textColorInherit.Mui-selected:hover {
  border-top: 4px solid #0047ba;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 6px solid #274463 !important;
}

.MuiTouchRipple-root,
.Mui-selected {
  // background-color: transparent !important;
  border-bottom-color: transparent !important;
}

.MuiListItem-gutters.MuiListItem-button a:hover {
  color: inherit !important;
  text-decoration: none !important;
}

.menu-close-button {
  top: 60px;
  border-radius: 50% !important;
  padding: 6px !important;
  left: -27px !important;
}

.align-end {
  align-items: flex-end;
}

.search-form {
  display: flex;
  align-items: flex-start;
}

.search-form .makeStyles-container2-151 {
  margin-top: 0px;
}

.w-100 {
  width: 100% !important;
}

.main-content {
  width: calc(100% - 245px);
  min-height: calc(100vh - 82px);
  position: relative;
  padding: 0px 10px 0px 20px;
  margin: 0;
  z-index: 12;
  ::-webkit-scrollbar-thumb {
    background: #aaafb9;
    @include boxShadow(inset 0 0 6px #999999);
    outline: 2px solid #999999;
    z-index: 99;
  }
}

.navbar-blue-bg.bottom {
  margin-top: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom-width: 0px !important;
  padding: 30px 0px;

  img {
    width: 185px;
    height: auto;
  }

  .small-logo {
    display: none !important;
  }
}

.navbar-blue-bg.bottom .sidemenu-heading {
  font-size: 14px;
}

.menu-close-button .MuiSvgIcon-root {
  font-size: 24px !important;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}


.MuiAppBar-colorPrimary .MuiButtonBase-root {
  //background-color:$PrimaryColor;
  opacity: 1;
  margin-right: 0.3rem;
  border-radius: 4px 4px 0px 0px;
  font-weight: bold;
  color: #545955;
  bottom: 0px;
}

.pt-20 {
  padding-top: 20px;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right-width: 0px !important;
}

.MuiAppBar-colorPrimary .MuiButtonBase-root .MuiTab-wrapper {
  text-transform: initial;
  font-size: 14px;
}

.PrivateTabIndicator-colorSecondary-63 {
  background-color: transparent !important;
}

.MuiAppBar-colorPrimary .MuiButtonBase-root:hover {
  //background-color: $PrimaryColor !important;
  /* box-shadow: 0px 0px 25px #005587; */
  z-index: 2;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.12);
  -ms-transition: all 100ms ease-in;
  -ms-transform: scale(1.12);
  -moz-transition: all 100ms ease-in;
  -moz-transform: scale(1.12);
  transition: all 100ms ease-in;
  transform: scale(1.12);
  border: 0px solid #ffffff;
  border: 0px !important;
}

.sidemenu-heading {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: $PrimaryColor;
}

.text-black {
  // color:rgba(0, 0, 0, 0.74) !important;
  //font-size: 0.750em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 13px !important;
}

.navbar-blue-bg .MuiSvgIcon-root {
  color: #fff !important;
}

.makeStyles-drawerPaper-7 {
  background-color: $PrimaryColor !important;
}

fieldset {
  border: 1px solid #aaafb9 !important;
  border-radius: 4px;
}

.custom-legend {
  width: 100% !important;
  padding: 9px 20px !important;
  background-color: $PrimaryColor;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px 4px 0px 0px;
}

.MuiFormHelperText-root {
  color: #000000 !important;
}

.MuiInputLabel-root {
  color: #000000 !important;
}

.MuiBox-root-254 {
  border-width: 0px !important;
}

.tab-holder>.MuiTypography-body1 {
  border-top: 1px solid #aaafb9;
  border-radius: 0px 0px 4px 4px;
}

.no-scrollbar::-webkit-scrollbar {
  width: 10px;
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none !important;
}

.thirdMenu {
  padding-left: 20px;
}

.MuiPickersToolbar-toolbar {
  background-color: $PrimaryColor !important;
}

.MuiPickersDay-daySelected {
  background-color: $PrimaryColor !important;

  &:hover {
    background-color: #3f51b5 !important;
  }
}

.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.form-wrapper.vertical-wrapper {
  flex-direction: column;
}

.ar-reverse {
  .mui-custom-form {
    width: calc(50% - 32px);
  }
}

.override-width-61 {
  width: 61% !important;
}

.override-width-95 {
  width: 95% !important;
}

.override-width-94 {
  width: 94% !important;
}

.override-width-45 {
  width: 45% !important;
}

.override-width-47 {
  width: 47% !important;
}

.override-width-42 {
  width: 42% !important;
}

.override-width-40 {
  width: 40% !important;
}

.override-width-44 {
  width: 44% !important;
}

.override-width-38 {
  width: 38% !important;
}

.override-width-40 {
  width: 40% !important;
}

.override-width-12-2 {
  width: 12.2% !important;
}

.override-width-10 {
  width: 10% !important;
}

.override-width-5 {
  width: 5% !important;
}

.override-width-30 {
  width: 30% !important;
}

.override-width-31 {
  width: 31% !important;
}

.override-width-62 {
  width: 61% !important;
}

.override-width-28 {
  width: 28% !important;
}

.override-height-32 {
  margin-top: 32px !important;
}

.override-width-25 {
  width: 25% !important;
}

.override-width-22 {
  width: 22.5% !important;
}

.override-width-23 {
  width: 23% !important;
}

.override-width-21 {
  width: 21% !important;
}

.override-width-20 {
  width: 20% !important;
}

.override-width-19 {
  width: 19% !important;
}

.override-width-18 {
  width: 18% !important;
}

.override-width-16 {
  width: 16% !important;
}

.override-width-95 {
  width: 95% !important;
}

.override-height-95 {
  height: 95% !important;
}

.override-width-98 {
  width: 98% !important;
}

.override-width-100 {
  width: 100% !important;
}

.override-m-06 {
  margin: 12px 0.6% !important;
}

.override-m-05 {
  margin: 12px 0.5% !important;
}

.override-m-1 {
  margin: 12px 1% !important;
}

.override-m-2 {
  margin: 12px 2% !important;
}

.override-m-08 {
  margin: 12px .8% !important;
}

.min-md {
  min-width: 190px !important;
}

.override-width {
  width: 15% !important;
}

.mui-custom-form {
  margin: 12px 2.5%;
  width: 20%;

  .MuiInput-root {
    height: 40px;
    margin-top: 20px;
    border: 0.8px solid #707070;
    border-radius: 5px;
    padding: 2px 12px;

    &:focus-within {
      border-color: #0147BA;
      //border-width: 1px;
    }

    &::before,
    &::after {
      border-width: 0px !important;
    }
  }

  .MuiFormLabel-asterisk {
    color: $ErrorColor !important;
  }

  .MuiFormLabel-root {
    font-size: 1.28em !important;
    /* 1.063em !important */
    white-space: nowrap;
    color: $PrimaryColor !important;
    font-weight: bold;
    letter-spacing: 0;
  }

  &.displayFilterFont {
    font-size: 0.8em !important;
  }

  .Mui-disabled {
    border-radius: 5px;
    border: 0px !important;

    &.MuiInputBase-root {
      background-color: #f4f4f4 !important;
    }

    .MuiInput-input {
      color: #000000 !important;
    }

    &::before {
      border-bottom-width: 0px;
    }
  }

  .MuiFormLabel-root.small-label {
    font-size: 14px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  .MuiFormLabel-root.fiscal-label {
    font-size: 12.5px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  .MuiFormLabel-root.very-small-label {
    font-size: 13px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  legend {
    width: 60px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .MuiInput-input {
    color: #000;
    font-size: 16px;
    width: 100%;
  }

  .sub-radio {

    // margin-top: 10px;
    label {
      margin: 0px;
    }

    font-size: 13px;

    .MuiFormControlLabel-root {
      margin: 0px;
    }

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }

    .MuiCheckbox-root {
      padding: 0px 5px 0px 0px;
      margin-left: -1px;
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        color: $PrimaryColor;
      }

      &:hover {
        background-color: rgba(39, 68, 99, 0.08);
      }
    }

    .MuiFormControlLabel-label {
      font-size: 13px;
    }
  }

  .MuiSelect-select {
    margin-top: 3px;
  }

  .MuiTextField-root,
  .MuiInput-input {
    width: 100% !important;
  }

  &.has-link {
    .Mui-disabled {
      .MuiInput-input {
        color: #0047BA !important;
        cursor: pointer;
      }

      &::before {
        border-bottom-width: 0px;
      }
    }
  }
}

.mui-custom-form-table {
  margin: 8px 2.5%;

  .MuiInput-root {
    height: 40px;
    margin-top: 20px;
    border: 0.8px solid #707070;
    border-radius: 5px;
    padding: 2px 12px;

    &:focus-within {
      border-color: #0147BA;
      //border-width: 1px;
    }

    &::before,
    &::after {
      border-width: 0px !important;
    }
  }

  .MuiFormLabel-asterisk {
    color: $ErrorColor !important;
  }

  .MuiFormLabel-root {
    font-size: 1.28em !important;
    /* 1.063em !important */
    white-space: nowrap;
    color: $PrimaryColor !important;
    font-weight: bold;
    letter-spacing: 0;
  }

  &.displayFilterFont {
    font-size: 0.8em !important;
  }

  .Mui-disabled {
    border-radius: 5px;
    border: 0px !important;

    &.MuiInputBase-root {
      background-color: #f4f4f4 !important;
    }

    .MuiInput-input {
      color: #000000 !important;
    }

    &::before {
      border-bottom-width: 0px;
    }
  }

  .MuiFormLabel-root.small-label {
    font-size: 14px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  .MuiFormLabel-root.fiscal-label {
    font-size: 12.5px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  .MuiFormLabel-root.very-small-label {
    font-size: 13px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  legend {
    width: 60px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .MuiInput-input {
    color: #000;
    font-size: 16px;
    width: 100%;
  }

  .sub-radio {

    // margin-top: 10px;
    label {
      margin: 0px;
    }

    font-size: 13px;

    .MuiFormControlLabel-root {
      margin: 0px;
    }

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }

    .MuiCheckbox-root {
      padding: 0px 5px 0px 0px;
      margin-left: -1px;
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        color: $PrimaryColor;
      }

      &:hover {
        background-color: rgba(39, 68, 99, 0.08);
      }
    }

    .MuiFormControlLabel-label {
      font-size: 13px;
    }
  }

  .MuiSelect-select {
    margin-top: 3px;
  }

  .MuiTextField-root,
  .MuiInput-input {
    width: 100% !important;
  }

  &.has-link {
    .Mui-disabled {
      .MuiInput-input {
        color: #0047BA !important;
      }

      &::before {
        border-bottom-width: 0px;
      }
    }
  }
}

.mui-custom-form-description {
  margin: 12px 2.5%;
  width: 45%;

  .MuiInput-root {
    height: 40px;
    margin-top: 20px;
    border: 0.8px solid #707070;
    border-radius: 5px;
    padding: 2px 12px;

    &:focus-within {
      border-color: #0147BA;
      //border-width: 1px;
    }

    &::before,
    &::after {
      border-width: 0px !important;
    }
  }

  .MuiFormLabel-asterisk {
    color: $ErrorColor !important;
  }

  .MuiFormLabel-root {
    font-size: 1.28em !important;
    /* 1.063em !important */
    white-space: nowrap;
    color: $PrimaryColor !important;
    font-weight: bold;
    letter-spacing: 0;
  }

  &.displayFilterFont {
    font-size: 0.8em !important;
  }

  .Mui-disabled {
    border-radius: 5px;
    border: 0px !important;

    &.MuiInputBase-root {
      background-color: #f4f4f4 !important;
    }

    .MuiInput-input {
      color: #000000 !important;
    }

    &::before {
      border-bottom-width: 0px;
    }
  }

  .MuiFormLabel-root.small-label {
    font-size: 14px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  .MuiFormLabel-root.fiscal-label {
    font-size: 12.5px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  .MuiFormLabel-root.very-small-label {
    font-size: 13px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  legend {
    width: 60px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .MuiInput-input {
    color: #000;
    font-size: 16px;
    width: 100%;
  }

  .sub-radio {

    // margin-top: 10px;
    label {
      margin: 0px;
    }

    font-size: 13px;

    .MuiFormControlLabel-root {
      margin: 0px;
    }

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }

    .MuiCheckbox-root {
      padding: 0px 5px 0px 0px;
      margin-left: -1px;
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        color: $PrimaryColor;
      }

      &:hover {
        background-color: rgba(39, 68, 99, 0.08);
      }
    }

    .MuiFormControlLabel-label {
      font-size: 13px;
    }
  }

  .MuiSelect-select {
    margin-top: 3px;
  }

  .MuiTextField-root,
  .MuiInput-input {
    width: 100% !important;
  }

  &.has-link {
    .Mui-disabled {
      .MuiInput-input {
        color: #0047BA !important;
      }

      &::before {
        border-bottom-width: 0px;
      }
    }
  }
}

.mui-custom-form.with-select {
  .MuiTextField-root {
    width: 100%;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.with-date {
  .MuiTextField-root {
    width: 100%;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.with-textarea {
  .form-control {
    font-size: 16px;
    color: #000;
  }
}

.mui-custom-form.with-icon {
  .MuiInput-root {
    margin-top: 0px;
  }

  .small-label {
    margin-bottom: 9px;
  }
}

.mui-custom-form.with-icon.with-icon-margin {
  .MuiInput-root {
    margin-top: 20px;
  }
}

.mui-custom-form.with-select {
  .MuiInput-root {
    margin-top: 20px;
  }
}

/*** Styling Form Elements end ***/

/*** Styling Data Table start ***/
.table-wrapper {
  width: 100% !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
}

.table-no-wrap {
  .customDataTable {
    .MuiTableHead-root {
      .MuiTableSortLabel-root {
        white-space: normal;
      }
    }
  }
}

.table-p-10 {
  .customDataTable {
    .MuiTableCell-root {
      padding: 7px 10px;
    }
  }
}

.table-p-5 {
  .customDataTable {
    .MuiTableCell-root {
      padding: 7px 5px;
    }
  }
}

.customDataTable {
  min-width: 300px;

  thead {
    border-radius: 5px 5px 0px 0px;

    tr {
      border-radius: 5px 5px 0px 0px;
    }

    th {
      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }

      .MuiSvgIcon-root {
        opacity: 0;
        font-size: 1.5em;
      }

      &:hover {
        .MuiSvgIcon-root {
          opacity: 1;
          font-size: 1.5em;
        }
      }

      .MuiCheckbox-root {
        .MuiSvgIcon-root {
          color: #fff !important;
          opacity: 1;
        }
        //Table heading SelectAll checkbox disabled indication
        &.MuiCheckbox-colorPrimary.Mui-disabled {
          .MuiSvgIcon-root{
            color: #A2ABB4 !important;
        }
        }
      }
    }
  }

  tfoot {
    border-radius: 0px 0px 5px 5px;

    tr {
      border-radius: 0px 0px 5px 5px;

      td {
        &:first-child {
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .MuiCheckbox-root {
    padding: 0px;
    font-size: 1em;
  }

  .MuiTableCell-root,
  .MuiTableRow-footer {
    padding: 7px 16px;
  }

  .MuiTableHead-root {
    background-color: $PrimaryColor;

    .MuiTableSortLabel-root {
      color: #fff;
      font-weight: bold;
      white-space: nowrap;

      .MuiSvgIcon-root {
        color: #fff !important;
      }
    }
  }

  .table-pagination {
    span {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 50%;
      background-color: #545955;
      text-align: center;
      color: #fff;
      line-height: 25px;
    }
  }
}

.tableNoWrap {
  table {
    .MuiTableHead-root {
      background-color: $PrimaryColor;

      .MuiTableSortLabel-root {
        white-space: pre-wrap !important;
      }
    }
  }
}

.fixedTable {
  table-layout: fixed;

  thead,
  tbody {
    tr {
      overflow: hidden;

      th,
      td {
        overflow: hidden;
      }
    }
  }

}
.table-adjust{
  thead{
    tr{
      th:first-child{
        padding-left: 10px;
      }
      th:nth-child(2),th:nth-child(4){
        padding-left: 5px;
      }
      th:nth-child(3),th:nth-child(6){
        padding-left: 8px;
      }
    }
  }
  tbody{
    tr{
      td:nth-child(2){
        padding-left: 10px;
      }
      td:nth-child(3){
        a{
        padding-left: 5px!important;
        }
      }
      td:nth-child(5){
        padding-left: 5px;
      }
      td:nth-child(4),td:nth-child(7){
        padding-left: 8px;
      }
    }
  }
}
.customDataTable.with-link {
  .MuiTableRow-root {
    a {
      display: contents;
      width: 100%;
      text-decoration: none !important;
    }
  }

  .has-link {
    padding: 0px;

    a {
      padding: 7px 16px;
      color: #002F61 !important;
      cursor: pointer;
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: underline !important;
    }
  }
}

/*** Styling Data Table end ***/

/*** Latest Styles For  Input  start ***/

.mui-custom-form.input-xs {
  .MuiInput-input {
    width: 60px;
  }

  .MuiFormHelperText-root {
    max-width: 60px;
  }
}

.mui-custom-form.input-15 {
  .MuiInput-input {
    width: 134px;
  }

  .MuiFormHelperText-root {
    max-width: 134px;
  }
}

.mui-custom-form.input-416 {
  .MuiInput-input {
    width: 416px;
  }

  .MuiFormHelperText-root {
    max-width: 416px;
  }
}

.mui-custom-form.input-146 {
  .MuiInput-input {
    width: 146px;
  }

  .MuiFormHelperText-root {
    max-width: 146px;
  }
}

.mui-custom-form.input-226 {
  .MuiInput-input {
    width: 226px;
  }

  .MuiFormHelperText-root {
    max-width: 226px;
  }
}

.mui-custom-form.input-sm {
  .MuiInput-input {
    width: 100px;
  }

  .MuiFormHelperText-root {
    max-width: 100px;
  }
}

.mui-custom-form.input-md {
  .MuiInput-input {
    width: 100%;
  }

  .MuiFormHelperText-root {
    max-width: 100%;
  }
}

.mui-custom-form.input-Adronment {
  .MuiInput-input {
    width: 173px;
  }

  .MuiFormHelperText-root {
    max-width: 173px;
  }
}

.mui-custom-form.input-xl {
  .MuiInput-input {
    width: 270px;
  }

  .MuiFormHelperText-root {
    max-width: 270px;
  }
}

.mui-custom-form.input-413.with-select {
  .MuiTextField-root {
    width: 413px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-450.with-select {
  .MuiTextField-root {
    width: 413px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-xll.with-select {
  .MuiTextField-root {
    width: 350px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-378.with-select {
  .MuiTextField-root {
    width: 378px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-325.with-select {
  .MuiTextField-root {
    width: 350px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-xxl {
  .MuiInput-input {
    width: 470px;
  }

  .MuiFormHelperText-root {
    max-width: 470px;
  }
}

.mui-custom-form.input-mx {

  .MuiInput-input,
  .MuiFormControl-root {
    width: 100%;
  }
}

.mui-custom-form.input-xs.with-select {
  .MuiTextField-root {
    width: 60px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-146.with-select {
  .MuiTextField-root {
    width: 146px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-226.with-select {
  .MuiTextField-root {
    width: 226px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-sm.with-select {
  .MuiTextField-root {
    width: 100px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-sm.with-icon {

  .MuiTextField-root,
  .MuiInput-root {
    width: 100px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-md.with-select {
  .MuiTextField-root {
    width: 100%;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-md.with-icon {

  .MuiTextField-root,
  .MuiInput-root {
    width: 190px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-15.with-icon {

  .MuiTextField-root,
  .MuiInput-root {
    width: 134px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-xl.with-select {
  .MuiTextField-root {
    width: 100%;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.mui-custom-form.input-xxl.with-select {
  .MuiTextField-root {
    width: 470px;

    .MuiInput-input {
      width: 100%;
    }
  }
}

.custom-fieldset {
  padding: 0px 10px;
  height: 100%;

  legend {
    display: inline-block;
    width: auto;
    font-size: 15px !important;
    white-space: nowrap;
    color: #274463 !important;
    font-weight: bold;
    margin-left: 16px;
  }
}

.r-custom-fieldset {
  padding: 0px 10px;
  height: auto;

  legend {
    display: inline-block;
    width: auto;
    font-size: 15px !important;
    white-space: nowrap;
    color: #274463 !important;
    font-weight: bold;
    margin-left: 16px;
  }
}

/*** Latest Styles For  Input  end ***/
.alert-danger.custom-alert {
  background-color: lighten($ErrorColor, 55%) !important;
  border-color: lighten($ErrorColor, 50%) !important;

  li {
    color: $ErrorColor;
  }
}

.alert-success.alert-danger.custom-alert {
  background-color: lighten($SuccessColor, 55%) !important;
  border-color: lighten($SuccessColor, 50%) !important;

  li {
    color: $SuccessColor;
  }
}

.danger-text {
  color: $ErrorColor !important;
  font-size: 13px !important;
}

.MuiCheckbox-colorPrimary {
  .MuiSvgIcon-root {
    color: $PrimaryColor !important;
  }
}

.ml-80 {
  margin-left: 80px;
}

.ShowoidCheckBox {
  display: none;
}

.mdl-demo {
  margin-right: 15px;

  .dropdown {
    width: 100%
  }

  .dropdown-trigger {
    display: block !important;
    max-height: 132px !important;
  }

  .dropdown-content {
    max-height: 220px;
    overflow-y: auto;
    width: 100%;
  }
}

.very-small-label {
  font-size: 11px !important;
}

.password-left-padding {
  padding-left: 20px;
}

/*************** Login CSS ************/

.shadow-box {
  border-radius: 2px;
  box-shadow: 0px 10px 10px grey;
  height: 100%;
}

.login-head-text {
  color: #fff;
  padding: 12px;
  font-size: 15px;
  width: 100%;
  background-color: $PrimaryColor;
  margin-bottom: 0px;
}

.login-box {
  padding: 10px 30px;

  ul {
    padding-left: 0px;
    margin-bottom: 0px;

    li {
      padding: 10px 0px;
      line-height: 15px;
    }

    &.with-border {
      li {
        border-bottom: 1px solid grey;

        &:last-child {
          border-bottom-width: 0px;
        }
      }
    }

    &.with-links {
      a {
        text-decoration: none;
        color: $PrimaryColor;
        font-size: .3cm;
      }
    }
  }
}

.btn-login {
  border: 2px solid #274463 !important;
  width: 100%;
  margin: 0px 0px 5px 0px;
  color: #fff !important;
  background-color: $PrimaryColor !important;

  &:hover,
  &:active {
    color: #fff !important;
    background-color: $PrimaryColor !important;
  }
}

.submitButton {
  justify-content: center;

  small {
    color: $PrimaryColor;
    margin-top: 3px;
  }
}

.login-checkbox {
  margin-top: 5px;
  margin-bottom: 0px;

  .MuiButtonBase-root {
    padding-top: 5px;
  }

  .MuiTypography-root {
    font-size: 14px;
  }
}

.login-carousel {
  border: 1px solid #fff;
  border-radius: 1px;
  box-shadow: 0px 4px 16px 0px grey;
  height: 100%;
  padding: 0px !important;
  background-color: grey;

  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100%;
  }

  .carousel-item {
    position: relative;

    .dark-bg {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

//User Action Button
.top-navbar {
  width: calc(100% - 240px) !important;
  height: 65px;

  .MuiToolbar-regular {
    padding-right: 0px;
  }

  .header-image {
    width: 100%;
    height: 100%;
    margin-Left: 50px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

/*--user-dropdown CSS--*/
.user-action-btn {
  text-align: left;

  .btn {
    background-color: transparent !important;
    border-width: 1px !important;
    color: #000 !important;
    font-weight: bold;
    @include boxShadow(none !important);
    padding: 8px !important;

    .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      left: -2px;
      position: relative;
      top: -2px;
    }
  }

  .dropdown-menu {
    margin: 0px;
    padding: 0px;

    @include border-radius(0rem 0rem 0.25rem 0.25rem);
    width: 100%;

    .dropdown-item {
      border-bottom: 1px solid #dadddc !important;
      background-color: transparent !important;
      padding: 0px;

      &:last-child {
        border-bottom-width: 0px;
      }

      a {
        text-decoration: none !important;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        color: #000 !important;
        background-color: transparent !important;
        @include animate-css(all 0.2s linear);

        &:hover {
          background-color: #dadddc !important;
          padding: 10px 15px 10px 20px;
        }
      }
    }
  }

  button.dropdown-toggle {
    border: solid 1px rgba(0, 0, 0, 0) !important;
    font-size: 1em !important;
  }

  .dropdown-menu.show {
    right: 0px !important;
    left: auto !important;
    transform: translate3d(0px, 45px, 0px) !important;
    -webkit-transform: translate3d(0px, 42px, 0px) !important;
    @include border-radius(4px);
  }
}

/*--user-dropdown CSS end--*/

.li-menu-with-active {
  border-radius: 9px;

  a:hover {
    background-color: #5797ff !important;
  }
}

.has-link {
  input {
    width: auto !important;
    color: #0047BA !important;
  }

  a {
    color: #0047BA !important;
  }

  a:hover {
    text-decoration: underline !important;
  }
}

.has-fcn-link {
  input {
    display: none;
    color: #0047BA !important;
  }

  a {
    color: #0047BA !important;
  }

  a:hover {
    text-decoration: underline !important;
  }
}

.hide-image {
  display: none;
}

.unsaved-changes-popup.popup1-padding {
  .swal2-actions {
    margin: 0px 0px 0px 0px !important;
  }

  .swal2-popup {
    padding: 0px;
  }

  .swal2-popup {
    width: 19em;
    height: 5.3em;
  }

  .button-text-lft {
    margin-left: 130px;
  }


}

.unsaved-changes-popup2 {
  .swal2-popup {
    width: 19em;
    height: 8em;
    z-index: 1300;

    h4 {
      // font-size: 1.3rem;
      font-size: 14px;
      line-height: 20px;
    }

    .poppuptextalign {
      text-align: left;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0px 0px 12px 10px;
    }

    .swal2-actions {
      margin: 0.5em auto 0;
    }
  }

  .swal2-icon-show {
    width: 3em;
    height: 3em;
    margin: 8px;

    .swal2-icon-content {
      font-size: 2.1em;
    }
  }
}

.unsaved-changes-popup1 {
  .swal2-popup {
    width: 23em;
    height: 10em;
    z-index: 1300;

    h4 {
      // font-size: 1.3rem;
      font-size: 14px;
      line-height: 20px;
    }

    .poppuptextalign {
      text-align: left;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0px 0px 12px 10px;
    }

    .swal2-actions {
      margin: 0.5em auto 0;
    }
  }

  .swal2-icon-show {
    width: 3em;
    height: 3em;
    margin: 8px;

    .swal2-icon-content {
      font-size: 2.1em;
    }
  }
}

.unsaved-changes-popup {
  .swal2-popup {
    width: 22em;
    height: 8em;
    z-index: 1300;

    h4 {
      // font-size: 1.3rem;
      font-size: 14px;
      line-height: 20px;
    }

    .poppuptextalign {
      text-align: left;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0px 0px 12px 10px;
    }

    .swal2-actions {
      margin: 0.5em auto 0;
    }
  }

  .swal2-icon-show {
    width: 3em;
    height: 3em;
    margin: 8px;

    .swal2-icon-content {
      font-size: 2.1em;
    }
  }
}

.swal2-container.swal2-shown {
  z-index: 13000;
  background-color: rgba(0, 0, 0, .4);
}

.h-100 {
  height: 100% !important;
}

.h-login-container {
  height: 100%;
  padding: 20px 10px 0 10px;
}

.login-container {
  .login-image-section {
    background: #fff !important;
    position: relative;
    border-radius: 0px 15px 15px 0px;
  }

  .label {
    font-size: 8px;
    position: absolute;
    top: 12px;
  }

  .login-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    opacity: 0.9;
    overflow: hidden;
    border-radius: 0px 15px 15px 0px;

    img {
      border-radius: 0px 15px 15px 0px;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .m-h-600 {
    height: 600px;
    overflow: auto;
  }

  .content-box {
    background: #2744637E 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 21px 24px;
    backdrop-filter: blur(10px);
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }

    .content-box-heading {
      color: #fff;
      padding-bottom: 3px;
      font-size: 16px;
      font-weight: 600;

      .heading-toggle {
        float: right;
        padding-top: 5px;
        font-size: 10px;
        font-weight: 400;
        cursor: pointer;

        .fa {
          margin-left: 5px;
        }
      }
    }

    .content-body {
      padding-top: 6px;

      .list-group-item {
        background: transparent;
        border-width: 0px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        margin-left: -12px;
        padding: 5px 12px;

        &:hover {
          background: #fff;
          color: black;
          border-radius: 2px;
        }
      }
    }
  }

  .content-box.green-border {
    .content-box-heading {
      border-bottom: 3px solid #00a89D;
    }
  }

  .content-box.purple-border {
    .content-box-heading {
      border-bottom: 3px solid #B57FEC;
    }
  }
}

.content-box.make-small {
  .content-body {
    height: 60px;
    overflow-y: hidden;
  }
}

.carosuel-tools {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .carousel-arrows {
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 52px;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    .fa {
      font-size: 25px;
    }
  }

  .carousel-text {
    color: #fff;
    width: 70%;
    padding-left: 30px;
    background: rgba(63, 89, 116, 0.01);

    /* update for 508 compliance */
    div {
      &:first-child {
        font-size: 17px;
        font-weight: 400;
      }

      &:nth-child(2) {
        font-size: 42px;
        font-weight: 300;
      }
    }

    .carousel-underline {
      background-color: #ff8700;
      width: 40px;
      height: 2px;
      align-self: center;
    }

    .carosuel-count {
      font-size: 10px !important;
      font-weight: 400;
      margin-left: 5px;
    }
  }
}

.label {
  border-radius: 10px;
  padding: 2px 6px;
}

.label.label-danger {
  background-color: #A81F00;
}

.bg-white {
  background-color: #fff !important;
}


.custom-save-dialog {
  .swal2-popup {
    width: 26em;
  }

  .swal2-success-circular-line-left {
    top: -2.5375em !important;
  }

  .swal2-icon.swal2-success {
    display: block !important;
  }

  .swal2-icon-show {
    width: 3em !important;
    height: 3em !important;
    margin: 10px;

    .swal2-success-line-tip {
      top: 1.7em !important;
      left: 0.575em !important;
      width: 0.9625em !important;
    }

    .swal2-success-line-long {
      top: 1.475em !important;
      right: 0.4em !important;
      width: 1.675em !important;
    }
  }
}

.dashboard-heading {
  color: #274463;
  padding: 12px 5px;
  font-weight: 600;
  font-size: 1.35em;
  letter-spacing: 0.1px;
  margin-bottom: 0px;
}

.db-r-data .dashboard-heading {
  font-size: 1rem;
  padding: 5px 0 10px;
  border-bottom: solid 1px #e4e6e9;
  margin-bottom: 10px;
}

.db-r-data ul li {
  padding-bottom: 3px;
}

.db-r-data ul {
  list-style: inside;
  padding-left: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #545955;
}

.sub-header {
  font-weight: bold;
  font-size: 14px;
  color: $PrimaryColor;
}

.full-menu .main-footer {
  padding-left: 35px;
  padding-right: 15px;
}

.main-footer  {
  p, a {
    font-family: "Open Sans", sans-serif, Arial;
  }
  .footer-text {
    font-size: 0.90em;
    color: #545955;
    opacity: 1 !important;
    text-align: center;
    width: 65%;
    margin-bottom: 0.7em;
  }
}
.main-footer {
  padding-top: 15px;
  .nav-menu {
    height: auto;
    line-height: 29px;
    white-space: nowrap;

    span {
      padding-right: 0px;
      border-right-width: 0px;

      a {
        border-right: 1px solid $SecondaryColor;
        padding: 0 $size5;
        color: $SecondaryColor $Imp;
        font-size: 0.84em; //fix made to add consistency in design on all three portals
        font-weight: 600;
        text-decoration: none $Imp;
        letter-spacing: 0.035em; //fix made specifically to accomodate this issue.
        &:hover {
          text-decoration: underline $Imp;
          color: $PrimaryColor $Imp; // fix made to add consistency in design on all three portals
        }
      }

      &:last-child {
        a {
          border-right-width: 0px;
        }
      }
      }
    }
  }

.top-bell-icon {
  font-size: 16px;
  color: #AAAFB9;
  display: inline-block;
  position: relative;
}

.top-bell-icon .fa:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #A81F00;
  top: 0;
  right: -3px;
}

// .footer-bottom-text
// {
//   color: #000;
//   font-size: 0.788em;
//   margin-bottom: 0px;
//   letter-spacing: -0.4px;
//   font-weight: 600;
// }
.image-responsive {
  border-radius: 5px;
  min-height: 60px;

  img {
    height: 100%;
    width: 100%;
    // height:auto;
    border-radius: 5px;
  }
}

.banner-section {
  max-height: 80px;

  position: relative;
  border-radius: 5px;
  background: transparent linear-gradient(278deg, #327EA8 0%, #082D44 100%) 0% 0% no-repeat padding-box;

  .banner-text {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);

    .small-text {
      font-size: 13px;
    }

    .large-text {
      font-size: 24px;
    }
  }
}

.bt-reset {
  padding: 9px 20px;
  color: #274463 !important;
  font-weight: 600 !important;
  font-size: 0.8em !important;
  /* 1em */
  border-radius: 5px;
  padding: 8px15px !important;
  text-transform: uppercase;
  border-width: 0px !important;
}

.btn-reset {
  background-color: transparent !important;
  border: 1pxsolid#274463 !important;
}

.dashboard-heading {
  color: #274463;
  padding: 12px 5px;
  font-weight: 600;
  font-size: 1.35em;
  letter-spacing: 0.1px;
  margin-bottom: 0px;
}

.dashboardtable {
  .table-content {
    td {
      color: #000000;
      font-size: 0.800em !important;
      padding-left: 0px;
    }

    td.timetext {
      color: #545955 !important;
    }

    .upgrade-btn {
      font-size: 0.727em !important;
      white-space: nowrap;
      background-color: #F4F4F4 !important;
      color: #274463 !important;
      box-shadow: none !important;
    }

    .btn-transparent {
      font-size: 0.727em !important;
      white-space: nowrap;
      box-shadow: none !important;
      border: 1px solid #274463 !important;
    }
  }
}

.upgrade-content {
  background-color: #0147BA !important;
  border-radius: 5px;

  .upgrade-btn {
    padding: 11px 19px !important;
    font-size: 0.682em !important;

  }
}

.dashboard-hdg {
  color: #FFFFFF;
}

.alert-content {
  span.alertview {
    color: #0047BA;
    text-align: right;
    float: right;
    padding: 13px;
    font-size: 0.727em;
  }

  .alert-dangericon {
    color: #FFFFFF;
    font-size: 0.727em;
  }

  .alert-contenttext {
    background-color: #FFF;
    color: #000000;
    border-radius: 5px;
    min-height: 43px;
    padding: 5px;

    .alerts {
      float: left;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;

      color: #545955;
      text-align: left;
    }

    .alertsdate {
      float: left;
      font-size: 0.75rem;
      color: #545955;
      white-space: nowrap;
      text-align: left;
    }
  }



  .alert-contenttextcontent {
    background-color: #FFF;
    color: #000000;
    border-radius: 5px;
    height: 40%;
    padding: 5px;

    .alerts {
      float: left;
      font-size: 0.938em;
      text-align: left;
      font-weight: 600;
      padding-left: 0px !important;
    }

    .alertsdate {
      float: left;
      font-size: 0.750em;
      color: #AAAFB9;
      white-space: nowrap;
      text-align: left;

    }

  }
}

.loginInputWrapper {
  display: flex;
  align-items: center;
  justify-items: center;
}

.login-credential-box {
  width: 100%;

  .forgotpassword {
    color: #0047BA !important;
    /* update for 508 compliance */
    font-size: 0.9em;
  }

  .forgot-password {
    font-size: 13px;
    color: #0047ba !important;
    text-decoration: none !important;
  }

  .termscondition {
    color: #000;
    font-size: 0.9em;
    padding: 10px 5px;
    display: inline;
  }

}

.padTop-10 {
  padding-top: 10px;
}

.margin-left-66 {
  margin-left: 66px;
}

.margin-left-22 {
  margin-left: 22px;
}

.mui-custom-form-content {
  margin: 12px 7px !important;
}

.mui-custom-form-content1 {
  margin: 12px 6px !important;
}

.mui-custom-form-content2 {
  margin: 10px 5px !important;
}

.table-custom-width {
  width: 100% !important;
}

.custom-dialog.dialog2-900 {
  .MuiDialog-paperWidthSm {
    max-width: 900px;
    max-height: 900px;
    top: 0px;
    left: 30px;
    padding-bottom: 10px;
  }
}

.MuiFormLabel-root.very-small-label {
  font-size: 13px !important;
  margin-bottom: 12px;

  span {
    color: $ErrorColor;
  }
}

.custom-dialog.dialog2-900 {
  .MuiDialog-paperWidthSm {
    max-width: 900px;
    max-height: 900px;
    top: 0px;
    left: 30px;
    padding-bottom: 10px;
  }
}

.custom-dialog.dialog-520 {
  .MuiDialog-paperWidthSm {
    width: 520px;
  }
}


.override-margin-rgt1 {
  margin-right: 2.5%;
}

.override-margin-rgt2 {
  margin-right: 3.5%;
}

.override-margin-lft1 {
  margin-left: 3.5%;
}

.override-margin-rgt2 {
  margin-right: 6%;
}

.form-control-text {
  font-size: 14px;
  color: #000;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 0;
}

#correct-icon {
  color: #00b300;
  font-size: 22px;
}

#close-icon {
  color: red;
  font-size: 22px;
}

#close-icon-approval {
  color: red;
  font-size: 22px;
  margin-left: -15px;
}

@media all {
  .hide-on-screen {
    display: none;
  }
}


.mui-custom-form {
  label.text-black {
    margin-left: 5px !important;
    vertical-align: text-top;
    line-height: 21px;
  }

  span.text-black {
    margin-left: 3px !important;
    vertical-align: text-top;
    line-height: 21px;
  }

  .sub-radio {
    span.text-black.sub-label {
      margin-left: 0px !important;
      vertical-align: text-top;
      line-height: 20px;
    }
  }
}

.MuiTypography-body1 {
  .hide-on-print {
    .alert-danger {
      li {
        font-size: 0.875rem;
      }
    }
  }
}

.mapsettablelist {
  a {
    li {
      padding: 6px 2px;
    }

    li:hover {
      color: #545955 !important;
      background-color: rgba(0, 0, 0, 0.04);
      padding: 6px 2px;
    }
  }

  .mapdefinition-li-active {
    color: #545955 !important;
    background-color: rgba(0, 0, 0, 0.04);
    display: block;
  }
}

.notes-table-content {
  background-color: #fff8f0;
  border: 1px solid #aaafb9;
  margin-bottom: 8px;
  font-size: 14px;
  word-break: break-all;
  margin: 0px 15px;
}

.contacts-table-content {
  word-break: break-all;
}
.notes-text {
  margin-top: 38px !important;
}

.notes-text-history {
  margin: 5px 0px;
}

.btn-tab-pannel {
  font-size: 0.67em !important;
  white-space: nowrap;
  box-shadow: none !important;
  border: 1px solid #274463 !important;
}

.bt-tabreset {
  padding: 9px 20px;
  color: #274463 !important;
  font-weight: 600 !important;
  font-size: 0.67em !important;
  /* 1em */
  border-radius: 5px;
  padding: 8px15px !important;
  text-transform: uppercase;
  border-width: 0px !important;
}

.btn-tabreset {
  background-color: transparent !important;
  border: 1pxsolid#274463 !important;
}

.outlineTab {
  border-bottom: 1px solid #aaafb9
}

.MuiAppBar-positionStatic {
  position: static;
  transform: none !important;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  :last-child.MuiTablePagination-root {
    padding: 7px 55px !important;
  }

  .MuiTablePagination-toolbar {
    padding: 0px !important;
  }
}

input[type=text]::-ms-clear {
  display: none !important;
}

.MuiTableCell-alignRight {
  text-align: right;
  flex-direction: unset !important;
}

// Text Editor Multiline alignment Bug#54828
.rdw-center-aligned-block>.public-DraftStyleDefault-block>span {
  text-align: center;
  width: 100%;
  display: block;
}

.rdw-right-aligned-block>.public-DraftStyleDefault-block>span {
  text-align: right;
  width: 100%;
  display: block;
}

.text-blue {
  color: #274463;
  font-weight: 650;
  padding: 0 5px;
}

.checkbox-font .MuiTypography-body1 {
  font-size: 13px !important;
  white-space: nowrap;
  color: #274463 !important;
  font-weight: bold;
  letter-spacing: 0;
}

.checkbox-font .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
  margin-right: -11px;
  flex-direction: row-reverse;
}

.checkbox-font .MuiFormControlLabel-labelPlacementTop {
  margin-left: 0px;
  flex-direction: column-reverse;
}

.checkbox-font .MuiCheckbox-root {
  margin-right: 127px;
  margin-top: 7px;
}

.buttonBorderColor {
  border-color: transparent !important;
}

.margin-left-10 {
  margin-left: 10px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $PrimaryColor !important;
}

.dashboard-heading-notification {
  color: #FFFFFF;
  padding: 12px 0px;
  font-weight: 600;
  font-size: 1.35em;
  letter-spacing: 0.1px;
  margin-bottom: 0px;
}

td.makeStyles-responsiveFont-201 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 13px !important;
  color: #545955;
}

td.makeStyles-timetext-208 {
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 13px !important;
  color: #545955;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #707070 !important;
  border-radius: 5px;
  line-height: 30px !important;
  height: 2.83em !important;
  outline: none;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #707070 !important;
  border-right: none !important;
  border-radius: 3px 0 0 3px;
}

.label-conf {
  font-size: 0.28em !important;
  white-space: nowrap;
  color: #274463 !important;
  font-weight: bold;
  letter-spacing: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #FFFFFF !important;
  border-color: #707070 !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 145px !important;
  }
}

.navbar {
  padding: .2rem 1rem !important;
  @include boxShadow(1px 0px 8px rgba(0, 0, 0, 0.1) !important);
  height: 65px;
  width: calc(100%) !important;
  @include border-radius(5px !important);
}

.noPadding {
  padding: 0px !important;
}

.noPaddingTop {
  padding-top: 0px !important;
}

.colViewDeatils {
  margin-top: '8%' !important;
  margin-left: '10%' !important;
}

.main-content-margin {
  margin-top: -11px !important;
}

.custom-fieldset-label {
  display: inline-block;
  width: auto;
  font-size: 15px !important;
  white-space: nowrap;
  color: #274463 !important;
  font-weight: bold;
  margin-left: 24px;
}

#system-Payee-id-table {
  .MuiIconButton-label {
    width: 100%;
    display: none;
    align-items: inherit;
    justify-content: inherit;
  }
}

.btn-success-sso {
  border: 2px solid #02837B !important;
  width: 100%;
  margin: 0px 0px 5px 0px;
  color: #fff !important;
  background-color: #02837B !important;

  &:hover,
  &:active {
    color: #fff !important;
    background-color: #02837B !important;
  }
}

input[type='radio'].radio-table {
  -webkit-appearance: none;
  width: 14px;
  height: 16px;
  border: 1px solid #274463;
  border-radius: 50%;
  outline: none;
  // box-shadow:0 0 5px 0px gray inset;
}

input[type='radio'].radio-table:before {
  content: '';
  display: block;
  width: 65%;
  height: 65%;
  margin: 20% auto;
  border-radius: 55%;
}

input[type='radio'].radio-table:checked:before {
  background: #274463;
}

input[type='radio'].radio-table:checked {
  border: 2px solid #274463;
}

.btn-icon-only {
  padding: 4px 15px !important;

  .fa {
    font-size: 20px;
    margin-right: 0;
  }
}

.hide-dropdown-icon::after {
  content: none !important;
}

.tab-heading+.float-right,
.page-heading+.float-right {
  margin-top: 0.45rem !important;
  padding-top: 0 !important;

  .btn {
    margin-top: 0 !important;
    margin-left: 0.35rem !important;
  }
}

.btn-ic.btn-add-new {
  position: relative;
  padding-left: 28px !important;
}

.btn-ic.btn-add-new:after {
  font-family: 'FontAwesome';
  content: "\f067";
  position: absolute;
  left: 15px;
  top: 53%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-weight: normal;
  font-size: 0.6rem;
}

.customDataTable .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:only-child:empty {
  border: none;
}

.ca-indicator {
  width: 180px;

  .mui-custom-form {
    width: auto;
  }
}

.ca-comment {
  font-size: 20px;
}

// Print-Help button icon css start
.print-help-btn-wrapper {
  position: relative;
  z-index: 1;
  min-width: 63px;
  
  .btn:before,.btn-add-select:after {
    font-family: 'FontAwesomeIcon';
    font-size: 0.83rem;
    font-weight: 400;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
  }
  .top-navbar .MuiToolbar-regular {
    margin-right: 75px;
    .menu-close-button {
      display: none !important;
    }
  }

  .btn-print-new {
    box-shadow: none!important;
    -webkit-box-shadow: none!important;
    -ms-box-shadow: none!important;
    padding: 10px 13px !important;
    margin-right: 10px;
    background-color: #0a50c1!important;
    border-radius: 50% 50%!important;
    height: $size4 * 6;
  }
  .btn-print-new:before {
    content: "\f02f";
    color: #fff;
    font-size: 1.1rem;
    left: 6px;
    bottom: -13px;
  }

  .btn-help-new{
    box-shadow: none!important;
    -webkit-box-shadow: none!important;
    -ms-box-shadow: none!important;
    padding: 0.71rem 0.85rem !important;
  }
  .btn-help-new:before {
    content: "\f059";
    color: #005A64;
    font-size: 2rem;
    left: 38px;
  }
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    ::-webkit-scrollbar {
      width: 0 !important
    }
  }
  .page-heading + .hide-on-print,
  .hide-on-screen {
    display: inherit !important;
  }
  .hide-on-print,
  .hide-print-footer {
    display: none !important;
  }

  .print-footer {
    height: auto;
    visibility: visible; 
    overflow: auto;
    margin-top: 10rem;
    .main-footer {
      .print-col-4 {
        width: calc(100% - 70%) !important;
        float: left;
        .left-bottom-logo.bottom {
          justify-content: left;
          background: none;
          border: 0;
        }
      }
      .print-col-8 {
        width: calc(100% - 30%) !important;
        float: left;
      }
    }
  }

  .para-truncate table tr td.MuiTableCell-root {
    //flex: 1;
    white-space: normal !important;
    overflow: auto;
    text-overflow: unset !important;
  }
  .hide-show-tbl-row {
    .table-wrapper {
      .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover {
        display: none;
      }
      .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover.Mui-selected {
        display: table-row;
      }
    }
  }
  .rquired-header + .tab-body,
  .page-header + .tab-body,
  .rquired-header + .tab-holder > .tab-body,
  div[class*='-header'] + .custom-hr + .tab-body {
    border: solid 1px #ececec;
  }
  .main-content {
    width: 100%;
    padding: 10px 20px 0;
    .navbar {
      display: flex;
      width: 100%;
    }
  }
  .content-wrapper {
    min-height: auto !important;
    padding: 80px 0 15px !important;
  }
  body {
// visibility: hidden;
    -webkit-print-color-adjust: exact;
  }

  .section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  .container-fluid {
    .col-lg-9#main-col {
      width: calc(100% - 25%);
    }
    .col-lg-3#sidebar-div {
      width: calc(100% - 75%);
    }
  }
}

// Print-Help button icon css ends
.mui-custom-form-select {
  margin: 12px 2.5%;
  width: 20%;

  .Mui-error,
  .Mui-required,
  .MuiFormLabel-asterisk {
    color: $ErrorColor !important;
  }

  .Mui-disabled {
    border-radius: 5px;
    border: 0px !important;

    &.MuiInputBase-root {
      background-color: #f4f4f4 !important;
    }

    .MuiInput-input {
      color: #000000 !important;
    }

    &::before {
      border-bottom-width: 0px;
    }
  }

  .MuiFormControl-root {
    width: 100% !important;
    max-width: calc(100% - 2px) !important;
  }

  .MuiFormLabel-root,
  .MuiCardHeader-root .MuiTypography-h5 {
    font-size: 1.22em !important;
    white-space: nowrap;
    color: $PrimaryColor !important;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    text-transform: capitalize;
  }

  .MuiInput-root {
    margin-top: 23px;
    border: 1px solid #707070;
    @include border-radius(5px);
    padding: 2px 10px;
    height: 38px;
    @include animate-css(all 0.2s linear);

    &:after,
    &:before {
      border: none !important;
    }

    .MuiIconButton-root {
      padding: 10px 5px;

      &:focus {
        outline: none;
      }
    }
  }

  .MuiInput-root:hover,
  .MuiInput-root:focus {
    border-color: $PrimaryColor;
  }

  .MuiInput-input {
    color: #000;
    height: 1.1875em;
    box-sizing: content-box;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .MuiInput-inputMultiline {
    min-height: 16px !important;
    max-height: 65px !important;
    padding: 6px 0 7px;
    overflow: auto !important;
    resize: vertical !important;
  }

  .Mui-disabled {
    .MuiInput-input {
      color: #545955;
    }

    &::before {
      border-bottom-width: 0px;
    }
  }

  .MuiFormLabel-root.small-label {
    font-size: 14px !important;
    margin-bottom: 12px;

    span {
      color: $ErrorColor;
    }
  }

  legend {
    width: 60px;
  }

  .input-text {
    color: #000;
    font-size: 16px;
  }

  .sub-radio {
    margin-top: 10px;
    font-size: 13px;
    overflow: hidden;

    label {
      margin: 0px;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    .MuiCheckbox-root {
      padding: 0px 4px 0px 0px;
      position: relative;
    }

    .Mui-checked {
      .MuiSvgIcon-root {
        color: $PrimaryColor;
      }

      &:hover {
        background-color: rgba(39, 68, 99, 0.08);
      }
    }

    .MuiFormControlLabel-label {
      font-size: 13px;
      font-weight: 400;
      padding-top: 2px;
      color: #545955;
      position: relative;
      left: -3px;
    }
  }

  .set-sub-radio {
    position: relative;
    left: -10px;
  }

  .MuiSelect-select {
    margin-top: 3px;
  }

  .Table-select {
    width: 140px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  @media (max-width: 591px) {
    width: 100%;

    .MuiTextField-root,
    .MuiInput-input {
      width: 100% !important;
    }
  }

  .MuiInputAdornment-positionStart {
    margin-right: 6px;
  }
}

.custom-input-select,
.wrap-form-label .custom-input-select {
  .MuiInput-root {
    margin-top: 0 !important;
    width: 100% !important;
    padding-left: 0;
  }
}

.custom-input-select {
  .MuiInputLabel-root {
    font-size: 0.95rem !important;
    margin: 3px 0 6px;
  }

  .required-label {
    color: $ErrorColor !important;
    margin-right: 0;
    padding-right: 1px;
  }

  &.mui-custom-form .MuiFormLabel-asterisk {
    position: relative;
    padding-right: 1px;
  }
}

.MuiNativeSelect-select {
  padding-left: 10px !important;
}

.tooltip-desc,
.MuiInput-root.disabled-view-data a,
.tooltip-heading {
  color: #000;
  font-size: 12px;
  letter-spacing: 0.05rem;
}
.w-97 {
  width: 97% !important;
  margin-bottom: 24px !important;
  padding: 2px 12px;
}
// Footer Links
.custom-panel .panel-header {
  color: $WhiteColor;
  font-size: 15px;
  font-weight: 600;
}
.sitemap-container {
  background-color: $WhiteColor;
  a {
    font-size: 1rem;
    color: #007bff !important;
    text-decoration: none !important;
    &:hover {
      color: $PrimaryColor !important;
      text-decoration: underline !important;
    }
  }
}
/*--footer-nav-items--*/
.footer-nav-items span:hover a {
  text-decoration: underline !important;
  color: $PrimaryColor !important;
}
.footer-nav-items {
  overflow: hidden;
}
.footer-nav-items span {
  line-height: 1;
  display: inline-block;
}
.footer-nav-items span a {
  border-right: 1px solid $PrimaryColor;
  padding: 0 5px;
  color: #0047ba !important;
  font-size: 0.84em;
  font-weight: 600;
  text-decoration: none !important;
  letter-spacing: .035em;
}
.footer-nav-items span:last-child a,
.footer-nav-items span:last-child {
  border-right: 0;
}
/*--footer-nav-items-end--*/
.mfComponent {
  .MuiExpansionPanelDetails-root.pb-0{
    padding-bottom: 0px !important;
  }
  .MuiExpansionPanelDetails-root.pt-0{
    padding-top: 0px !important;
  }
}

 //Table heading and checkbox Indication
.MuiCheckbox-colorPrimary.Mui-disabled .MuiSvgIcon-root{
  color: #A2ABB4 !important;
}
.table-heading{
  h2{
      color: #000;
      padding: 5px 0px;
      height: 100%;
      font-weight: 600;
      font-size: 1.135em !important;
      letter-spacing: 0.1px;
      border-bottom:solid 1px #707070
  }
}


.radioDisabled{
  background: #f4f4f4;
  color: #000;
  padding: 5px 12px;
  height: 40px;
  border-radius: 5px;
  line-height: 31px;
  font-weight: normal;
  font-size: 16px;
}
.AddressLine1{
  span{
    margin: -4px;
  }
}
.MuiTypography-colorTextSecondary{
	color:#545955 !important;
}